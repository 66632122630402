
<template>
  <MglMap
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    :center="coordinates"
    :zoom="zoom"
  >
    <MglMarker :coordinates="coordinates" color="red">
      <MglPopup>
        <VCard>
          <div>Unimarc Los Militares</div>
        </VCard>
      </MglPopup>
    </MglMarker>
  </MglMap>
</template>
<script>
import Mapbox from "mapbox-gl"
import { MglMap, MglMarker, MglPopup } from "vue-mapbox"

  export default {
    components: {
      MglMap,
      MglMarker,
      MglPopup
    },
    data () {
      return {
        accessToken: 'pk.eyJ1IjoiYXh0ZXJvaWQiLCJhIjoiY2wzb3cybWtnMDE3ZzNkcDZ3eWswaW15eSJ9.JAethI13wvW5-rCDF7VRiA',
        mapStyle: 'mapbox://styles/mapbox/light-v9',
        coordinates: [-70.5669692, -33.4023576],
        zoom: 16
      }
    },
    created () {
      // We need to set mapbox-gl library here in order to use it in template
      this.mapbox = Mapbox
    }
  }
</script>
